import styled from "@emotion/styled";
import { Button } from "@vds/buttons";
import { Grid, Row } from "@vds/grids";
import { Notification } from "@vds/notifications";
import { lazy, useContext, useState } from "react";
import { Header, SLInput } from "../../components/core/Controls";
import { Spacer, SLGridLayout, SLRowLayout } from "../../components/core/Utility";
import ViewUnitInfo from "../../components/features/CreateVehiclesHelperComponents/SearchRevealUnitsTitle/ViewUnitInfo";
import { ACCOUNT_REGEX, TECHNICALID_REGEX, WORK_TICKET_REGEX } from "../../constants/ValidationConstants";
import { CreateVehicleContext } from "../../context/createVehicleContext";
import { createRevealVehicles } from "../../services/searchVtuUnit/createRevealVehicles";

const ContentWrapper = styled.div`
  padding: 10px 0px;
`;

export default function ViewUnit() {
  const { revealVehicle, vehicle, esn } = useContext(CreateVehicleContext);
  const { setRevealVehicle: setRevealVehicleValue } = revealVehicle;
  const { vehicle: vehicleValue } = vehicle;
  const { setEsn: setEsnValue } = esn;
  const [technicalIDValue, setTechnicalIDValue] = useState(vehicleValue.technicalReferenceID);
  const [accountUIDValue, setAccountUIDValue] = useState(vehicleValue.accountId);
  const [workTicketValue, setWorkTicketValue] = useState(vehicleValue.workOrderNumber);
  const [vehicleTrackingId, setVehicleTrackingId] = useState("");
  const [msgValue, setMsgValue] = useState("");
  const [msgTypeValue, setMsgTypeValue] = useState("");
  const [errTechnicalIDValue, setErrTechnicalIDValue] = useState("");
  const [errAccountUIDValue, setErrAccountUIDValue] = useState("");
  const [errWorkTicketValue, setErrWorkTicketValue] = useState("");

  const showErrorNotification = (msg) => {
    setMsgTypeValue("error");
    setMsgValue(msg);
  };

  const showSuccessNotification = (msg) => {
    setMsgTypeValue("success");
    setMsgValue(msg);
  };

  const validateInput = () => {
    if (!technicalIDValue.match(TECHNICALID_REGEX)) {
      setErrTechnicalIDValue("Provide a valid Technical ID");
    } else {
      setErrTechnicalIDValue("");
    }
    if (!accountUIDValue.match(ACCOUNT_REGEX)) {
      setErrAccountUIDValue("Provide a valid AccountUID");
    } else {
      setErrAccountUIDValue("");
    }
    if (!workTicketValue.match(WORK_TICKET_REGEX)) {
      setErrWorkTicketValue("Provide a valid Work Ticket");
    } else {
      setErrWorkTicketValue("");
    }
    if (!technicalIDValue.match(TECHNICALID_REGEX) || !accountUIDValue.match(ACCOUNT_REGEX) || !workTicketValue.match(WORK_TICKET_REGEX)) {
      let errMsg = "Provide a ";
      if (!technicalIDValue.match(TECHNICALID_REGEX)) {
        errMsg += "valid Technical ID, ";
      }
      if (!accountUIDValue.match(ACCOUNT_REGEX)) {
        errMsg += "valid AccountUID, ";
      }
      if (!workTicketValue.match(WORK_TICKET_REGEX)) {
        errMsg += "valid Work Ticket, ";
      }
      showErrorNotification(errMsg.substring(0, errMsg.length - 2));
      return true;
    } else {
      showErrorNotification("");
    }
    return false;
  };

  const handleRevealCreateVehicles = () => {
    let validationStatus = validateInput();
    if (!validationStatus) {
      createVehicles();
    }
  };

  const createVehicles = async () => {
    let response = await createRevealVehicles({
      esn: vehicleValue.esn,
      universalAccountId: accountUIDValue,
      workOrderNumber: workTicketValue,
      vehicleTrackingId: 0,
      technicalId: technicalIDValue,
    });
    if (response.status == 200) {
      setVehicleTrackingId(response.data.data.vehicleTrackingId);
      showSuccessNotification("Successfully created unit in reveal");
    } else {
      showErrorNotification(response.response?.data.message ?? "Error creating reveal vehicle");
    }
  };

  return (
    <>
      <Header>View Unit</Header>
      <ContentWrapper>
        <div
          style={{
            width: "100%",
            marginLeft: "-20px",
          }}
        >
          {msgValue !== "" ? <Notification type={msgTypeValue} title subtitle={msgValue} fullBleed={false} inlineTreatment={true} /> : ""}
        </div>
        <SLGridLayout>
          <Spacer top="1vw" />
          <SLRowLayout>
            <SLInput
              css={{ paddingBottom: "20px" }}
              value={technicalIDValue}
              onChange={(e) => setTechnicalIDValue(e.target.value)}
              error={errTechnicalIDValue !== ""}
              errorText={errTechnicalIDValue}
              type="text"
              label="Technical ID"
              width="80%"
            />
            <SLInput
              css={{ paddingBottom: "20px" }}
              value={accountUIDValue}
              onChange={(e) => setAccountUIDValue(e.target.value)}
              error={errAccountUIDValue !== ""}
              errorText={errAccountUIDValue}
              type="text"
              label="AccountUID"
              width="80%"
            />
            <SLInput
              css={{ paddingBottom: "20px" }}
              value={workTicketValue}
              onChange={(e) => setWorkTicketValue(e.target.value)}
              error={errWorkTicketValue !== ""}
              errorText={errWorkTicketValue}
              type="text"
              label="Work Ticket"
              width="80%"
            />
          </SLRowLayout>
          <SLRowLayout>
            <Button size="small" onClick={handleRevealCreateVehicles}>
              Submit
            </Button>
          </SLRowLayout>

          <SLRowLayout css={{ paddingTop: "10px" }}>
            <ViewUnitInfo esn={vehicleValue.esn} vehicleTrackingId={vehicleTrackingId} />
          </SLRowLayout>
        </SLGridLayout>
      </ContentWrapper>
    </>
  );
}

export const ViewUnitMetadata = {
  name: "View Unit",
  id: "view_unit",
  component: lazy(() => import("./ViewUnit")),
  route: "/view_unit",
};
